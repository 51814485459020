import React, { useEffect, useState } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import RNPickerSelect from 'react-native-picker-select'
import { useMutation, useQuery } from '@apollo/client'
import { winesQuery } from '../../../../../networking/queries'
import styled from 'styled-components/native'
import PillButton from '../../../../../components/PillButton';
import { CREATE_ROUND } from '../../../../../networking/mutations'
import useMixpanel from '../../../../../utils/useMixpanel'
import keyMap from '../../../keyMap'
import { uniq, path } from 'ramda'

const AddWineContainer = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: black;
  padding: 40px;
`

const SectionHeader = styled(Text)`
  font-size: 32px;
  font-weight: 300;
  color: white;
  margin-bottom: 40px;
`

const PickerContainer = styled(View)`
  margin-bottom: 20px;
`

const PickerInstructions = styled(Text)`
  font-size: 16px;
  color: white;
  margin-bottom: 12px;
`

const RedWhiteContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 40px;
`

const WineTypeContainer = styled(TouchableOpacity)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const WineTypeLabel = styled(Text)`
  color: white;
  margin-top: 10px;
`

const AddWine = ({ route, navigation }) => {
  
  const gameState = path(['params','gameState'], route)
  const roundId = path(['params', 'round', 'id'], route)
  const wine = path(['params', 'round', 'wine'], route)

  const gameId = gameState.id
  const [redWhiteValue, setRedWhiteValue] = useState(wine ? wine.type : undefined)
  const [selectedGrapeValue, setSelectedGrapeValue] = useState(wine ? wine.grape : undefined)
  const [selectedCountryValue, setSelectedCountryValue] = useState(wine ? wine.country : undefined)
  const [grapes, setGrapes] = useState([])
  const [countries, setCountries] = useState([])
  const [wines, setWines] = useState([])
  const [selectedWine, setSelectedWine] = useState(wine ? wine : undefined)
  const { data: wineData } = useQuery(winesQuery)
  const [createRound] = useMutation(CREATE_ROUND)
  const { trackWithProperties } = useMixpanel()

  const addSelectedWine = () => {
    createRound({
      variables: {
        wineId: selectedWine.id, 
        gameId, 
        roundId
      }
    })

    trackWithProperties('Added Round', {
      isPlayer: false,
      gameId,
      gameCode: gameState.shortCode,
      wineId: selectedWine.id, 
      roundId,
      playersInGame: gameState.players?.length
    })
    navigation.goBack()
  }

  useEffect(() => {
    if (wineData) {
      setWines(wineData.wines)
      const grapesWithDupes = wineData.wines.map( w => w.grape)
      const uniqueGrapes = uniq(grapesWithDupes).sort()
      const objectifiedGrapes = uniqueGrapes.map(g => ({ label: keyMap[g], value: g, key: g}) )
      setGrapes( objectifiedGrapes )
    }
  }, [wineData])

  useEffect(() => {
    if (redWhiteValue) {
      //setSelectedGrapeValue('')
      //setSelectedWine('')
      const winesByType = wines.filter(w => w.type === redWhiteValue)
      const grapesWithDupes = winesByType.map( w => w.grape)
      const uniqueGrapes = uniq(grapesWithDupes).sort()
      const objectifiedGrapes = uniqueGrapes.map(g => ({ label: keyMap[g], value: g, key: g}) )
      setGrapes( objectifiedGrapes )
    }
  }, [redWhiteValue, wines])

  useEffect(() => {
    if (selectedGrapeValue) {
      //setSelectedCountryValue('')
      const winesByGrape = wines.filter(w => w.grape === selectedGrapeValue)
      const sortedCountries = winesByGrape.sort( 
        function(a, b) { 
          if (a.country < b.country) {
            return -1
          } else if (a.country > b.country ) {
            return 1
          } else {
            return 0
          }
        }
      )
      setCountries(sortedCountries.map(w => ({label: keyMap[w.country], value: w.country, key: w.id}) ))
    }
  }, [selectedGrapeValue, wines])

  useEffect(() => {
    if (selectedCountryValue) {
      const selected = wines.filter(w => w.grape === selectedGrapeValue).filter(w => w.country === selectedCountryValue)
      setSelectedWine(selected[0])
    }
  }, [selectedCountryValue, wines])

  return (
    <AddWineContainer>
      <SectionHeader>Add a Wine</SectionHeader>
      <RedWhiteContainer>
        <WineTypeContainer onPress={() => setRedWhiteValue('white')}>
          <Image
            source={require(`../../../../../../assets/wineglass-${redWhiteValue === 'white' ? 'white' : 'empty'}.png`)}
            style={{width: 125, height: 125 }}
          /> 
          <WineTypeLabel>White</WineTypeLabel>
        </WineTypeContainer>
        <WineTypeContainer onPress={() => setRedWhiteValue('red')}>
          <Image
            source={require(`../../../../../../assets/wineglass-${redWhiteValue === 'red' ? 'red' : 'empty'}.png`)}
            style={{width: 125, height: 125 }}
          /> 
          <WineTypeLabel>Red</WineTypeLabel>
        </WineTypeContainer>
      </RedWhiteContainer>

      {!!redWhiteValue && <PickerContainer>
        <PickerInstructions>Select the grape ...</PickerInstructions>
        <RNPickerSelect
          selectedValue={selectedGrapeValue ? selectedGrapeValue : undefined}
          onValueChange={(itemValue, itemIndex) => setSelectedGrapeValue(itemValue)}
          value={selectedGrapeValue}
          items={grapes} 
          style={{inputWeb: { height: 30, borderRadius: 10, paddingLeft: 10 }}}
        />
      </PickerContainer>}

      {!!selectedGrapeValue && <PickerContainer>
        <PickerInstructions>... and the country of origin</PickerInstructions>
        <RNPickerSelect
          selectedValue={selectedCountryValue ? selectedCountryValue : undefined}
          onValueChange={(itemValue, itemIndex) => setSelectedCountryValue(itemValue)}
          value={selectedCountryValue}
          items={countries} 
          style={{inputWeb: { height: 30, borderRadius: 10, paddingLeft: 10 }}}
        />
      </PickerContainer>}
      {!!selectedWine && 
        <PillButton
          disabled={!selectedWine}
          label={'Add Wine'}
          onPress={addSelectedWine}
      />}
    </AddWineContainer>
  )
}

export default AddWine