import React, { useRef, useEffect } from 'react'
import { Animated, Text, View } from 'react-native'

const AnimatedContainer = (props) => {
  const { delay , style } = props
  const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0

  React.useEffect(() => {
    Animated.timing(
      fadeAnim,
      {
        delay,
        toValue: 1,
        duration: 500,
      }
    ).start();
  }, [fadeAnim])

  return (
    <Animated.View                 // Special animatable View
      style={{
        ...style,
        opacity: fadeAnim,         // Bind opacity to animated value
      }}
    >
      {props.children}
    </Animated.View>
  )
}

export default AnimatedContainer