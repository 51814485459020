import React from 'react'
import { Text, TextInput, View, KeyboardAvoidingView, Button, StyleSheet, Platform } from 'react-native'
import { path } from 'ramda'

import { useMutation, useLazyQuery } from '@apollo/client'
import { CREATE_USER, CREATE_PLAYER } from '../../networking/mutations'
import { validateEmail } from '../../utils/validateEmail'
import { gameQuery } from '../../networking/queries'

import useMixpanel from '../../utils/useMixpanel'
import { people } from 'mixpanel-browser'

function RegisterPlayer({ route, navigation }) {

  const fetchedGame = path(['params','fetchedGame'], route)
  const { trackWithProperties, identify, set, union } = useMixpanel()

  const [email, onChangeEmail] = React.useState('')
  const [player, setPlayer] = React.useState(null)
  const [displayName, onChangeDisplayName] = React.useState('')

  // We want to fetch one last time before pushing to the next screen, to catch any updates that
  // would be missed before the subscription takes over
  const [getGame, {loading: getGameLoading}] = useLazyQuery(gameQuery, {
    onCompleted: (data) => {
      if (data && data.game) {
        trackWithProperties('Joined Game', {
          gameCode: data.game.shortCode,
          gameId: data.game.id,
          playersInGame: data.game.players.length,
          email: email,
          displayName: player.displayName
        })
        navigation.push('Game', { fetchedGame: data.game, player })
      }
    },
    onError: error => {
      trackWithProperties('Triggered Error', {
        attemptType: 'query',
        attemptValue: 'gameQuery',
        errorMessage: error.message
      })
      console.log(error)
    },
    fetchPolicy: 'no-cache'
  })

  const [createUser, {loading: createUserLoading}] = useMutation(CREATE_USER)
  const [createPlayer, {loading: createPlayerLoading}] = useMutation(CREATE_PLAYER)
  const joinGame = async () => {
    // validate email
    if (!validateEmail(email)) {
      console.log('Email is not valid')
    }
    // validate display name (profanity)

    // create user
    const userData = await createUser({ variables: { email: email } })
    
    const userId = path(['data','createUser', 'id'], userData)

    const matchedUser = fetchedGame.players.filter(p => p.userId === userId)

    if (matchedUser && matchedUser.length > 0 && matchedUser[0].isActive) {
      console.log('A user with this email address is already active in this game')
    }
    const gameId = fetchedGame.id
    const variables = {
      displayName,
      userId,
      gameId
    }

    identify(userId)
    set({$email: email})
    union({displayNames: [displayName]})

    // create player
    const playerData = await createPlayer({ variables })
    setPlayer(path(['data','createPlayer'], playerData))

    // refetch the game; once complete, user will be passed to Game screen
    getGame({variables: {shortCode: fetchedGame.shortCode}})
  }

  return (
    <KeyboardAvoidingView 
      behavior={Platform.OS === "ios" ? "padding" : "height"}  
      style={styles.container}
    >
      <View style={styles.inputBlock}>
        <Text style={styles.inputLabel}>Email Address</Text>
      </View>
        <TextInput 
          style={styles.input}
          autoCompleteType={'email'}
          autoCorrect={false}
          keyboardType={'email-address'}
          textContentType={'emailAddress'}
          onChangeText={text => {
              onChangeEmail(text)
          }}
          
          value={email}
          placeholder={'Enter your email address'}
        />
      <View style={{justifyContent: 'flex-start', marginTop: 40, width: 300}}>
        <Text style={styles.inputLabel}>Display Name</Text>
      </View>
      <TextInput 
          style={styles.input}
          onChangeText={text => {
              onChangeDisplayName(text)
          }}
          value={displayName}
          placeholder={'What shall we call you?'}
        />
      <Button
        title="Let me in!"
        onPress={joinGame}
        color='rgb(136, 39, 52)'
        disabled={createUserLoading || createPlayerLoading || getGameLoading}
      />
    </KeyboardAvoidingView>
  )
}

export default RegisterPlayer

const styles = StyleSheet.create({
  container: { 
    flex: 1, 
    alignItems: 'center', 
    backgroundColor: 'black'
  },
  inputBlock: {
    justifyContent: 'flex-start', 
    width: 300,
    marginTop: 50
  },
  inputLabel: {
    color: 'white', 
    fontSize: 18
  },
  input: { 
    width: 300, 
    height: 40, 
    margin: '20px', 
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingLeft: '10px',
    marginTop: 10,
    color: 'rgb(136, 39, 52)', 
    fontWeight: '300', 
    outlineWidth: '0'
  }
})