import React from 'react'
import { View, Text } from 'react-native'
import ConfettiCannon from 'react-native-confetti-cannon'
import styled from 'styled-components/native'
import { path } from 'ramda'
import AnimatedContainer from '../../../../components/AnimatedContainer'
import AnimatedPodium from '../../../../components/AnimatedPodium'

const ResultsContainer = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: black;
  padding: 40px;
  paddingTop: 20px;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
const ResultsHeader = styled(Text)`
  font-size: 24px;
  font-family: 'Avenir';
  font-weight: 500;
  color: white;
  margin-bottom: 40px;
`

const PodiumContainer = styled(View)`
  display: flex;
  width: 100%;
  max-width: 800px;
  height: 350px;
  margin-top: 40px;
  flex-direction: row;
  align-items: flex-end;
`
const Podium = styled(View)`
  width: 33%;
  flex-direction: colum;
  justify-content: center;
  align-items: center;
`

const PodiumLabel = styled(Text)`
  font-size: 36px;
  font-weight: 500;
  color: white;
`

const PlayerName = styled(Text)`
  font-size: 24px;
  font-weight: 300;
  color: white;
  margin-bottom: 10px;
`

const FinalResults = ({ gameState, player }) => {
  
  const players = gameState.players.sort((a,b) => {return b.score - a.score})
  
  let currentDelay = 0
  let delayIncrement = 1250

  return (
    <ResultsContainer>
      <AnimatedContainer delay={currentDelay++}>
        <ResultsHeader>{`And now our final standings!`}</ResultsHeader>
      </AnimatedContainer>
      <PodiumContainer>
        <Podium>
          <AnimatedContainer delay={3600}>
            <PlayerName>{players[1] ? players[1].displayName : ''}</PlayerName>
          </AnimatedContainer>
          <AnimatedPodium delay={3000} targetHeight={200} style={{backgroundColor: 'rgb(136, 39, 52)'}}>
            <AnimatedContainer delay={3500}>
              <PodiumLabel>2nd</PodiumLabel>
            </AnimatedContainer>
          </AnimatedPodium>
        </Podium>
        <Podium>
          <AnimatedContainer delay={5600}>
            <PlayerName>{players[0].displayName}</PlayerName>
          </AnimatedContainer>
          <AnimatedPodium delay={5000} targetHeight={300} style={{backgroundColor: 'rgb(198,53,71)'}}>
            <AnimatedContainer delay={5500}>
              <PodiumLabel>1st</PodiumLabel>
            </AnimatedContainer>
          </AnimatedPodium>
        </Podium>
        <Podium>
          <AnimatedContainer delay={1600}>
            <PlayerName>{players[2]?.displayName}</PlayerName>
          </AnimatedContainer>
          <AnimatedPodium delay={1000} targetHeight={150} style={{backgroundColor: 'rgb(136, 39, 52)'}}>
            <AnimatedContainer delay={1500}>
              <PodiumLabel>3rd</PodiumLabel>
            </AnimatedContainer>
          </AnimatedPodium>
        </Podium>
      </PodiumContainer>
    </ResultsContainer>
  )
}

export default FinalResults