import React from 'react'
import { View, Text, ScrollView } from 'react-native'
import styled from 'styled-components/native'
import { path } from 'ramda'

import PlayerLine from './PlayerLine'

const StatusBoardContainer = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: black;
  padding: 20px;
`

const HeaderContainer = styled(Text)`
  margin-bottom: 10px;
`
const NameLabel = styled(Text)`
  font-size: 32px;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
`

const RoundLabel = styled(Text)`
  font-size: 28px;
  font-weight: 300;
  color: white;
  margin-bottom: 20px;
`

const Explainer = styled(Text)`
  font-size: 16px;
  color: white;
`

const PlayerList = styled(ScrollView)`
  flex-direction: column;
  margin-top: 20px;
`

const StatusBoard = ({ gameState, player }) => {
  
  
  const players = gameState.players.slice().sort((a,b) => {return b.score - a.score})

  const sortedRounds = gameState.rounds.slice().sort((a,b) => a.indexInGame - b.indexInGame)
  const incompleteRounds = sortedRounds.filter(r => !r.completed)
  const currentRound = incompleteRounds[0]

  const wineType = currentRound ? currentRound.wine.type : gameState.rounds[gameState.rounds.length - 1]?.wine?.type
  const getExplainerText = () => {
    let text = ''
    switch(gameState.phase) {
      case ('round'):
        text = 'Some people are still filling out their wine keys. Why not have a drink while you wait?'
        break;
      case ('welcome'):
        text = 'Welcome to the game! The Somm will begin the first round shortly.'
        break;
      case ('scoring'): 
        text = currentRound ? 'The round will start soon.' : 'All rounds have been completed!'
        break
      case ('end'): 
        text = 'The game is over! Here are our final standings:'
        break
      default: 
        text = 'Welcome to Sommify!'
        break
      } 
      return text
    }

    const explainerText = getExplainerText()
    const activeRound = incompleteRounds[0] ? incompleteRounds[0] : gameState.rounds[gameState.rounds.length - 1]

  return (
    <StatusBoardContainer>
      <HeaderContainer>
      {!!gameState.name && <NameLabel>{gameState.name} / </NameLabel>}
      <RoundLabel>{`Round ${activeRound ? activeRound.indexInGame + 1 : 1}`}</RoundLabel>
      </HeaderContainer>
      <Explainer>{explainerText}</Explainer>
      <PlayerList>
      {players.map( p => <PlayerLine remotePlayer={p} userPlayer={player} wineType={wineType} key={p.id}/>)}
      </PlayerList>

    </StatusBoardContainer>
  );
}

export default StatusBoard