import React, { useRef, useEffect } from 'react'
import { Animated, Text, View } from 'react-native'

const AnimatedPodium = (props) => {
  const { delay, style, targetHeight } = props
  const heightAnim = useRef(new Animated.Value(0)).current  // Initial value for height: 0

  React.useEffect(() => {
    Animated.timing(
      heightAnim,
      {
        delay,
        toValue: targetHeight,
        duration: 500,
      }
    ).start();
  }, [heightAnim])

  return (
    <Animated.View                 // Special animatable View
      style={{
        ...style,
        width: '100%', 
        borderLeft: '2px solid white', 
        borderRight: '2px solid white',
        borderTop: '2px solid white',
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: heightAnim,         // Bind height to animated value
      }}
    >
      {props.children}
    </Animated.View>
  )
}

export default AnimatedPodium