
const keyMap = {
  'gold': 'Gold',
  'straw': 'Straw',
  'yellow': 'Yellow',
  'moderate': 'Moderate',
  'moderatePlus': 'Moderate +',
  'moderateMinus': 'Moderate -',
  'neutral': 'Neutral',
  'pronounced': 'Pronounced',
  'high': 'High',
  'thin': 'Thin',
  'thick': 'Thick',
  'newWorld': 'New World',
  'oldWorld': 'Old World',
  'italy': 'Italy',
  'chile': 'Chile',
  'spain': 'Spain',
  'france': 'France',
  'southAfrica': 'South Africa',
  'austria': 'Austria',
  'california': 'California',
  'newZealand': 'New Zealand',
  'germany': 'Germany',
  'australia': 'Australia',
  'oregon': 'Oregon',
  'argentina': 'Argentina',
  'pinotGrigio': 'Pinot Grigio',
  'albarino': 'Albariño',
  'cheninBlanc': 'Chenin Blanc',
  'grunerVeltliner': 'Grüner Veltliner',
  'sauvignonBlanc': 'Sauvignon Blanc',
  'pinotGris': 'Pinot Gris',
  'chardonnay': 'Chardonnay',
  'torrontes': 'Torrontés',
  'viognier': 'Viognier',
  'gewurztraminer': 'Gewürztraminer',
  'riesling': 'Riesling',
  'gamay': 'Gamay',
  'pinotNoir': 'Pinot Noir',
  'zinfandel': 'Zinfandel',
  'sangiovese': 'Sangiovese',
  'grenache': 'Grenache',
  'nebbiolo': 'Nebbiolo',
  'tempranillo': 'Tempranillo',
  'merlot': 'Merlot',
  'malbec': 'Malbec',
  'shiraz': 'Syrah/Shiraz',
  'cabernetSauvignon': 'Cabernet Sauvignon'
}

export default keyMap