import React from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native'

const RoundContainer = styled(View)`
  flex-direction: column;
  background-color: rgb(200,200,200);
  padding: 10px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 10px;
  justify-content: flex-end;
  overflow: hidden;
`

const RoundNumber = styled(Text)`
  color: black;
  font-size: 24px;
  font-weight: 700;
`

const Round = ({ round }) => {

  const roundNumber = round.indexInGame + 1

  return (
      <RoundContainer>
        <RoundNumber>{`Round ${roundNumber}`}</RoundNumber>
      </RoundContainer>
  )
}

export default Round