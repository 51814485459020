import React, { useEffect, useState } from "react"
import { View, Text, FlatList, TouchableOpacity, ActivityIndicator } from "react-native"
import WineKeyButton from '../../../../components/WineKeyButton'
import styled from 'styled-components/native'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_WINEKEY } from '../../../../networking/mutations'
import { winesQuery } from '../../../../networking/queries'
import useMixpanel from '../../../../utils/useMixpanel'
import Constants from 'expo-constants'
import keyMap from '../../keyMap'
import { ScrollView } from "react-native-gesture-handler";
import { uniq } from 'ramda'


const Container = styled(View)`
  align-items: center;
  justify-content: flex-start;
  background-color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: 100%;
`

const HeaderContainer = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ButtonContainer = styled(View)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  padding: 20px;
  padding-top: 10px;
`

const Header = styled(Text)`
  font-size: 32px;
  color: white;
  font-weight: 300;
  margin-bottom: 20px;
`

const TastingInstructions = styled(Text)`
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
  margin-top: 20px;
`

const ChoiceList = styled(FlatList)`
  width: 100%;
  flex-grow: 0;
`

const Choice = styled(TouchableOpacity)`
  height: 60px;
  width: 80%
  background-color: white;
  margin: 0 auto;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`

const Label = styled(Text)`
  font-size: 28px;
  color: white;
`

const tastingInstructions = {
  /*'Sight': {
    main:`Take a look at the wine in your glass. (No tasting just yet!)`,
    red:`Tilt the glass at a 45-degree angle and hold it directly over the Light to Bold color reference.\n\nIf you can read the text through the wine, your wine might be made from a thin-skinned grape.\n\nIf you cannot read the text through the wine, your wine might be made from a thick-skinned grape.`,
    white:`When evaluating a white wine, determine what the color is. If it helps, hold the glass over the Sommify Wine Key color reference bar to see how it corresponds with Straw, Yellow, or Gold.`
  },
  'Acidity': {
    main:`Take a hefty sip of your wine.`,
    red:`Does the area under your tongue water intensely for several seconds? Does the wine take you to Drool School? That's higher acidity!`,
    white:`Does the area under your tongue water intensely for several seconds? Does the wine take you to Drool School? That's higher acidity!`
  },
  'Aromatic Intensity': {
    main: `Put your nose in your wine glass.`,
    white: `Does the fruit aroma jump out from the glass? That would be Aromatically Pronounced.\n\nDo fruit aromas appear muted or subtle? That would be Aromatically Neutral.`
  },
  'Tannin': {
    main: `Take a sip from your glass.`,
    red: `Does the wine dry your tongue out like you just licked a wool sock? That's High tannin!\n\nTanning makes the palate feel astringent. It can be felt specifically on the middle of the tongue and the front part of the mouth.`
  }, */
  'World': {
    main: `Put your nose in your wine glass.`,
    red: `If fruit dominates, there's a good chance the wine is from the New World (everywhere but Europe). If "other" aromas and flavors dominate (earthiness and funkiness for reds, minerality for whites), it could be from the Old World (Europe).`,
    white: `If fruit dominates, there's a good chance the wine is from the New World (everywhere but Europe). If "other" aromas and flavors dominate (earthiness and funkiness for reds, minerality for whites), it could be from the Old World (Europe).`
  }
}

const Item = ({ title, onPress, wineType, selected }) => {

  const selectedColor = wineType === 'red' ? 'rgb(198,53,71)' : 'rgb(255,204,102)'
  const backgroundColor = selected ? selectedColor : 'gray'
  return (
  <Choice onPress={onPress} style={{backgroundColor}}>
    <Label>{title}</Label>
  </Choice>
  )
}

const WineKey = ({gameState, player}) => {

  const [currentWineKeyIndex, setCurrentWineKeyIndex] = useState(0)
  const [selectedGrapeLocal, setSelectedGrapeLocal] = useState(null)
  const [selectedCountryLocal, setSelectedCountryLocal] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const [wines, setWines] = useState([])
  const [grapes, setGrapes] = useState([])
  const [possibleCountries, setPossibleCountries] = useState([])
  const [possibleWorld, setPossibleWorld] = useState()
  const [choices, setChoices] = useState([])

  const gameRounds = gameState.rounds.slice().sort((a,b) => a.indexInGame - b.indexInGame)
  const currentRound = gameRounds.filter(r => !r.completed)[0]
  const wineType = currentRound.wine.type
  const wineKeyOptions = currentRound.wineKeyOptions
  const currentWineKey = wineKeyOptions[currentWineKeyIndex]
  const currentWineKeyLabel = currentWineKey.label
  const currentWineKeyInternalName = currentWineKey.label === 'Aromatic Intensity' ? 'intensity' : currentWineKey.label.toLowerCase()
  const { data: wineData} = useQuery(winesQuery)
  const { trackWithProperties } = useMixpanel()
  const version = Constants.manifest.version 
  const environment = Constants.manifest.extra.environment

  const [updateWineKey, {loading: wineKeyUpdating}] = useMutation(UPDATE_WINEKEY, {
    onCompleted: (data) => {
      if ( currentWineKeyIndex < wineKeyOptions.length - 1 ) {
        // Update state with next set of wine key options
        trackWithProperties('Updated Wine Key', {
          playerId: player.id,
          currentWineKey: currentWineKey.label,
          roundId: currentRound.id,
          clientVersion: version,
          clientEnvironment: environment
        })
        setCurrentWineKeyIndex(currentWineKeyIndex => currentWineKeyIndex + 1)
      } else {
        // Move us back to the game screen to wait for round results
        trackWithProperties('Submitted Wine Key', {
          playerId: player.id,
          displayName: player.displayName,
          roundId: currentRound.id,
          clientVersion: version,
          clientEnvironment: environment
        })
      }
    },
    onError: error => {
      console.log(error)
      trackWithProperties('Triggered Error', {
        attemptType: 'mutation',
        attemptValue: 'UPDATE_WINEKEY',
        errorMessage: error.message,
        gameId: gameState?.id,
        shortCode: gameState?.shortCode,
        clientVersion: version,
        clientEnvironment: environment
      })
    }
  })

  const updateSelected = item => {
    if (currentWineKeyLabel === 'Grape') {
      setSelectedGrapeLocal(item.id)
    } else if (currentWineKeyLabel === 'Country') {
      setSelectedCountryLocal(item.id)
    }
    setSelectedId(item.id)
  }

  const submitWineKeyValue = async (label, value) => {
    const variables = {
      playerId: player.id,
      roundId: currentRound.id
    }

    // Special handling of this two word characteristic
    if (label === 'Aromatic Intensity') {
      label = 'Intensity'
    }
  
    variables[label.toLowerCase()] = value

    setSelectedId(null)
    await updateWineKey({variables})
  }

  const stepBack = () => {
    setCurrentWineKeyIndex(currentWineKeyIndex => currentWineKeyIndex - 1)
  }

  const renderItem = ({ item }) => {

    const itemLabel = keyMap[item.title] ? keyMap[item.title] : item.title

    return  (
      <Item 
        title={itemLabel} 
        onPress={ () => updateSelected(item) } 
        selected={item.id === selectedId} 
        wineType={wineType}
      />
    )
  }

  useEffect(() => {
    if (wineData) {
      setWines(wineData.wines)
      const grapesWithDupes = wineData.wines.map( w => w.grape)
      const uniqueGrapes = uniq(grapesWithDupes).sort()
      const objectifiedGrapes = uniqueGrapes.map(g => ({ label: keyMap[g], value: g, key: g}) )
      setGrapes( objectifiedGrapes )
    }
  }, [wineData])

  useEffect(() => {
      const tempChoices = wineKeyOptions[currentWineKeyIndex].options.map(o => ({
        id: o,
        title: o
      }))

      setChoices(wineKeyOptions[currentWineKeyIndex].options.map(o => ({
        id: o,
        title: o
      })))
      if (currentWineKeyLabel === 'Country') {
        setChoices(possibleCountries.map(c => ({
          id: c.value,
          title: c.value
        })))
      } else if (currentWineKeyLabel === 'World') {
        setChoices([{id: possibleWorld.value, title: possibleWorld.label}])
      }
  }, [wineKeyOptions, currentWineKeyIndex])

  useEffect(() => {
    if (selectedGrapeLocal) {
      const winesByGrape = wines.filter(w => w.grape === selectedGrapeLocal)
      const sortedCountries = winesByGrape.sort( 
        function(a, b) { 
          if (a.country < b.country) {
            return -1
          } else if (a.country > b.country ) {
            return 1
          } else {
            return 0
          }
        }
      )
      const countryChoices = sortedCountries.map(w => ({label: keyMap[w.country], value: w.country, key: w.id}) )
      setPossibleCountries(countryChoices)
    }
  }, [selectedGrapeLocal, wines])

  useEffect(() => {
    if (selectedCountryLocal) {
      const winesByCountry = wines.filter(w => w.country === selectedCountryLocal)
      const worldChoice = winesByCountry.map(w => ({label: keyMap[w.world], value: w.world, key: w.id}))[0]
      setPossibleWorld(worldChoice)
    }
  }, [selectedCountryLocal])

  return (
    <Container >
      <View style={{paddingLeft: 20, width: '100%', flexDirection: 'row'}}>
        <Header>{currentWineKeyLabel}</Header>
      </View>
      <ScrollView style={{width: '100%', maxWidth: '640px', padding: 20}}>
        <HeaderContainer>
        
          { (currentWineKeyLabel !== 'Country' && currentWineKeyLabel !== 'Grape') &&
          <>
            <TastingInstructions>{tastingInstructions[currentWineKeyLabel].main}</TastingInstructions>
            <TastingInstructions>{tastingInstructions[currentWineKeyLabel][wineType]}</TastingInstructions>
          </>
        }
        </HeaderContainer>
      {wineKeyUpdating ? 
      <ActivityIndicator color='#ffffff'/>
      :
      <ChoiceList
        data={choices}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        scrollEnabled={currentWineKeyLabel === 'Country' || currentWineKeyLabel === 'Grape'}
      />
}
      </ScrollView>
      <ButtonContainer style={{justifyContent: `${currentWineKeyIndex === 0 ? 'flex-end' : 'space-between'}`}}>
        {currentWineKeyIndex !== 0 && 
        <WineKeyButton 
          onPress={() => stepBack()}
          label='PREVIOUS'
          wineType={wineType}
        />
}
        <WineKeyButton 
          disabled={!selectedId || wineKeyUpdating}
          onPress={() => submitWineKeyValue(currentWineKeyLabel, selectedId)}
          label='NEXT'
          wineType={wineType}
        />
      </ButtonContainer>
      
    </Container>
  )

}

export default WineKey