import React, { useEffect, useState } from 'react'
import { path, mergeDeepRight } from 'ramda'
import WineKey from './components/wineKey/WineKey'
import StatusBoard from './components/statusBoard/StatusBoard'
import RoundResults from './components/roundResults/RoundResults'
import FinalResults from './components/finalResults/FinalResults'
import SommView from './components/sommView/SommView'

import { useMutation, useSubscription, useQuery } from '@apollo/client'
import { DEACTIVATE_PLAYER } from '../../networking/mutations'
import { gameQuery } from '../../networking/queries'
import { GAME_UPDATED, PLAYERS_CHANGED } from '../../networking/subscriptions'

import { storeGameState, getGameState, storePlayerState, getPlayerState, clearGameState, clearPlayerState } from '../../utils/storageManager'
import useMixpanel from '../../utils/useMixpanel'
import Constants from 'expo-constants'

const Game = ({ route, navigation }) => {

  const version = Constants.manifest.version 
  const environment = Constants.manifest.extra.environment
  
  // Set initial state off the route params, if available
  // (i.e. if the player has just been forwarded from the registration page)
  const [player, setPlayer] = useState(path(['params','player'], route))
  const [gameState, setGameState] = useState(path(['params','fetchedGame'], route))
  const [roundResultsComplete, setRoundResultsComplete] = useState(false)
  const [shouldRefresh, setShouldRefresh] = useState(false)

  const { trackWithProperties, reset } = useMixpanel()

  // Player received from route 
  if (player) {
    // Persist to asyncstorage for retrieval later
    storePlayerState(player)
  } else {
    // Player needs to be fetched from storage if possible
    // (i.e., player came back after leaving or reloaded browser tab)
    getPlayerState().then((value) => {
      if (value) {
        console.log('Pulling player from storage')
        console.log(value)
        setPlayer(value)
      }
    })
  }

  // Same basic pattern, but for the game state itself
  if (gameState) {
    storeGameState(gameState)
  }

  if (!gameState || gameState === 'undefined') {
    getGameState().then((value) => {
      console.log('Setting gamestate: loading from storage')
      console.log(value)
      setGameState(value)
      setRoundResultsComplete(false)
      setShouldRefresh(true)
    })
  }

  console.log(gameState?.shortCode)

  const { data: freshGame, loading: freshGameLoading, error: freshGameError } = useQuery(gameQuery, {
    variables: {
      shortCode: gameState?.shortCode
    },
    skip: !gameState || gameState === 'undefined'
  })

  const [deactivatePlayer] = useMutation(DEACTIVATE_PLAYER)
  const { data: gameData, loading: gameSubscriptionLoading, error: gameSubscriptionError } = useSubscription(
    GAME_UPDATED,
    { 
      variables: { id: gameState?.id }, 
      skip: !gameState || gameState === 'undefined'
    }
  )

  if (gameSubscriptionError) {
    console.log(gameSubscriptionError)
    trackWithProperties('Triggered Error', {
      attemptType: 'subscription',
      attemptValue: 'gameSubscription',
      errorMessage: gameSubscriptionError.message,
      gameId: gameState?.id,
      shortCode: gameState?.shortCode,
      clientVersion: version,
      clientEnvironment: environment
    })
  }

  const { data: playerData, loading: playerSubscriptionLoading, error: playerSubscriptionError } = useSubscription(
    PLAYERS_CHANGED,
    { 
      variables: { gameId: gameState?.id },
      skip: !gameState || gameState === 'undefined'
    },
  )

  if (playerSubscriptionError) {
    console.log(playerSubscriptionError)
    console.log(gameState)
    trackWithProperties('Triggered Error', {
      attemptType: 'subscription',
      attemptValue: 'playerSubscription',
      errorMessage: playerSubscriptionError.message,
      gameId: gameState?.id,
      shortCode: gameState?.shortCode,
      clientVersion: version,
      clientEnvironment: environment
    })
  }

  useEffect(() => {
    if (freshGame && shouldRefresh) {
      console.log('Setting gamestate: Fresh game fetched')
      console.log(freshGame)
      setGameState(freshGame.game)
      storeGameState(freshGame.game)
      setShouldRefresh(false)
    }
  }, [freshGame, shouldRefresh])

  useEffect(() => {
    if (playerData) {
      const newGameState = mergeDeepRight(gameState, {players: playerData.playersChanged} )
      console.log('setting gamestate: merging players')
      console.log(newGameState)
      setGameState(newGameState)
      if (player) {
        const userPlayer = playerData.playersChanged.filter(p => p.id === player.id)[0]
        setPlayer(player => mergeDeepRight(player, userPlayer ))
      }
      storeGameState(newGameState)
    }
  }, [playerData])

  useEffect(() => {
    if (gameData && !freshGameLoading) {
      let newGameState = mergeDeepRight(gameState, gameData.gameUpdated)
      console.log('setting gamestate: update from gamedata subscription')
      console.log(newGameState)
      setGameState(newGameState)
      storeGameState(newGameState)
    }
  }, [gameData, freshGameLoading])

  // Warn player before allowing tab / browser closure
  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault()
      e.returnValue = ''
      return ''
    })
    return () => {
      window.removeEventListener('beforeunload', (e) => {
        e.preventDefault()
        e.returnValue = ''
        return ''
      },  {capture: true})
    }
  }, [])

  // Warn user before allowing back navigation, then deactivate their player
  useEffect(
    () => 
      navigation.addListener('beforeRemove', (e, player) => {
        // Prevent default behavior of leaving the screen
        e.preventDefault()

        // Prompt the user before leaving the screen
        const confirmed = confirm('You are about to leave the game — are you sure?')
        if (confirmed === true) {
          if (player) {
           deactivatePlayer({variables: {id: player.id}})
          }
          trackWithProperties('Left Game', {
            gameCode: gameState?.shortCode,
            gameId: gameState?.id,
            playerId: player?.id,
            playersInGame: gameState?.players?.length,
            clientVersion: version,
            clientEnvironment: environment
          })
          reset()
          clearGameState()
          getPlayerState().then((value) => {
            if (value) {
              deactivatePlayer({variables: {id: value.id}})
            }
          })
          clearPlayerState()
          navigation.dispatch(e.data.action)
        } 
      }),
    [navigation]
  )

  useEffect(() => {
    if (!gameState?.phase) return
    if ( (gameState.phase === 'scoring' || gameState.phase =='end') && !roundResultsComplete) {
      let timerLength = 20
      const resultsTimer = setInterval(function() {
        timerLength--
        if ( timerLength <= 0 ) {
          setRoundResultsComplete(true)
          clearInterval(resultsTimer)
        }
      }, 1000)
    } else if ( gameState.phase === 'round' ) {
      setRoundResultsComplete(false)
    } 
  }, [gameState, gameState?.phase])

  if (!gameState) {
    return null
  }

  if (!player) {
    // somm view
    return <SommView gameState={gameState} navigation={navigation} />
  } else {
    // regular player view
    const shouldShowResults = (!roundResultsComplete && player.wineKeys && player.wineKeys.length)
    switch (gameState.phase) {
      case 'round': 
        return player.ready ? <StatusBoard gameState={gameState} player={player} /> :  <WineKey gameState={gameState} player={player} />
      case 'scoring':
        return  shouldShowResults ? 
          <RoundResults 
            gameState={gameState} 
            setRoundResultsComplete={setRoundResultsComplete} 
            player={player} 
          />: 
          <StatusBoard 
            gameState={gameState} 
            player={player} 
          />
      case 'end': 
        return (
          <FinalResults
            gameState={gameState} 
            player={player} 
          />)
      default: 
        return  <StatusBoard 
                  gameState={gameState} 
                  player={player} 
                />
    }
  }
}

export default Game;