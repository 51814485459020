import AsyncStorage from '@react-native-async-storage/async-storage'

const GAME_STATE = 'GAME_STATE'
const PLAYER_STATE = 'PLAYER_STATE'

let gameState
let player

/* GETTERS */
export const getGameState = async () => {
  if (gameState && gameState !== 'undefined') {
    const parsedGameState = JSON.parse(gameState)
    return parsedGameState
  }

  gameState = await AsyncStorage.getItem(GAME_STATE)
  
  if (gameState && gameState !== 'undefined') {
    const parsedGameState = JSON.parse(gameState)
    return parsedGameState
  } else {
    return null
  }
  
}

export const getPlayerState = async () => {
  if (player) {
    const parsedPlayerState = JSON.parse(player)
    return Promise.resolve(parsedPlayerState)
  }

  player = await AsyncStorage.getItem(PLAYER_STATE)
  const parsedPlayerState = JSON.parse(player)
  return parsedPlayerState
}

/* SETTERS */
export const storeGameState = (gameState) => {
  gameState = JSON.stringify(gameState)
  return AsyncStorage.setItem(GAME_STATE, gameState)
}

export const clearGameState = () => {
  gameState = null
  return AsyncStorage.removeItem(GAME_STATE)
}

export const storePlayerState = (player) => {
  player = JSON.stringify(player)
  return AsyncStorage.setItem(PLAYER_STATE, player)
}

export const clearPlayerState = () => {
  player = null
  return AsyncStorage.removeItem(PLAYER_STATE)
}

// * TBD * //

export const signIn = (newToken, user) => {
  // There are two user types. An adhoc user with no token required (allowing regular gameplay)
  // ... and an auth user with token required (allowing admin functions like setup)

  // If this is an auth user, we need to set their token and store it

  if (user.type === 'auth' && newToken) {
    token = newToken
    AsyncStorage.setItem(AUTH_TOKEN, newToken)
  }

  // Otherwise, we need to store the user and return
  currentUser = JSON.stringify(user)
  return AsyncStorage.setItem(CURRENT_USER, currentUser)
}

export const signOut = () => {
  token = undefined
  return AsyncStorage.removeItem(AUTH_TOKEN)
}

