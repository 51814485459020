import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

const TimerControlContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;
`

const Incrementer = ({ stepSize, timerLength, broadcastNewTimerLength }) => {
  const label = stepSize < 0 ? '-' : '+'
  return (
    <TouchableOpacity style={{border: '1px solid white', width: '20px', height: '20px', borderRadius: '5px'}} onPress={() => broadcastNewTimerLength(timerLength + stepSize * 60000)}>
      <Text style={{color: 'white', textAlign: 'center'}}>{label}</Text>
    </TouchableOpacity>
  )
}


const TimerControl = ({ timerLength, broadcastNewTimerLength}) => {
  return (
    <TimerControlContainer>
      <Incrementer stepSize={-1} timerLength={timerLength} broadcastNewTimerLength={broadcastNewTimerLength} />
      <Text style={{color: 'white', paddingLeft: '10px', paddingRight: '10px'}}>{`${timerLength / 60000} min`}</Text> 
      <Incrementer stepSize={1} timerLength={timerLength} broadcastNewTimerLength={broadcastNewTimerLength} />
    </TimerControlContainer>
  )
}

export default TimerControl