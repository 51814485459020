import gql from 'graphql-tag'

export const PLAYERS_CHANGED = gql`
  subscription playersChanged($gameId: String!) {
    playersChanged(gameId: $gameId) {
      id
      ready
      displayName
      score
      wineKeys {
        id
        roundId
        world
        country
        grape
      }
    }
  }
`

export const GAME_UPDATED = gql`
  subscription gameUpdated($id: String!) {
    gameUpdated(id: $id) {
      id
      name
      isActive
      timerActive
      timerLength
      shortCode
      phase
      currentRound
      rounds {
        id
        indexInGame
        completed
        wineKeyOptions {
          label
          options
        }
        wine {
          id
          type
          grape
          country
          world
        }
      }
      isActive
    }
  }
`