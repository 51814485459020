import gql from 'graphql-tag'

export const gameQuery = gql`
  query game($shortCode: String!) {
    game(shortCode: $shortCode) {
      id
      name
      shortCode
      isActive
      timerActive
      timerLength
      phase
      currentRound
      rounds {
        id
        indexInGame
        completed
        wineKeyOptions {
          label
          options
        }
        wine {
          id
          type
          grape
          country
          world
        }
      }
      players {
        id
        userId
        isActive
        displayName
        ready
        score
        wineKeys {
          id
          roundId
          world
          country
          grape
        }
      }
    }
  }
`

export const winesQuery = gql`
  query wines {
      wines {
        id
        type
        grape
        country
        world
        acidity
        tannin
        intensity
      }
    }
`

export const winesByCharacteristic = gql`
  query winesByCharacteristic($characteristic: String!, $value: String!) {
      winesByCharacteristic(characteristic: $characteristic, value: $value) {
        id
        type
        grape
        country
        world
        acidity
        tannin
        intensity
      }
    }
`