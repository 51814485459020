import { ApolloClient, split, HttpLink, InMemoryCache } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from 'apollo-link-context'
import Constants from 'expo-constants'
import { getPlayerState } from './storageManager'

const LOCAL_HTTP_URI = 'http://localhost:4000/graphql'
const LOCAL_WS_URI = 'ws://localhost:4000/graphql'

const DEV_HTTP_URI = 'https://sommify-backend-dev.herokuapp.com/graphql'
const DEV_WS_URI = 'wss://sommify-backend-dev.herokuapp.com/graphql'

const NODE_ENV = Constants.manifest.extra.environment

export const initApollo = () => {

  const httpLink = new HttpLink({
    uri: NODE_ENV === 'development' ? LOCAL_HTTP_URI : DEV_HTTP_URI
  })

  const wsLink = new WebSocketLink({
    uri: NODE_ENV === 'development' ? LOCAL_WS_URI : DEV_WS_URI,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const player = await getPlayerState()
        console.log(player)
        return ({
          connectedPlayer: player?.id,
          connectedGame: player?.gameId
        })
      }
    }
  })

  const authLink = setContext(async (req, { headers }) => {
    const player = await getPlayerState()

    return {
      ...headers,
      headers: {
        connectedPlayer: player?.id,
        connectedGame: player?.gameId
      },
    }
  })
  
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink)
  )
  
  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  });
 
  return client
}