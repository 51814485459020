import React from 'react'
import { View, ScrollView } from 'react-native'
import PillButton from '../../../../../components/PillButton'
import useMixpanel from '../../../../../utils/useMixpanel'
import Wine from './Wine'
import Round from './Round'
import styled from 'styled-components/native'

const WineListContainer = styled(View)`
  flex-direction: row;
  margin-bottom: 20px;
  align-content: space-between;
`

const RoundContainer = styled(View)`
  flex-direction: column;
  height: 140px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`

const WineList = ({gameState, navigation, activeRound, screenShareEnabled, players, unreadyPlayers, setGamePhase, setGamePhaseLoading}) => {

  const { trackWithProperties } = useMixpanel()
  const startRound = () => {
    // set game phase to "round"
    trackWithProperties('Started Round', {
      isPlayer: false,
      gameId: gameState.id,
      gameCode: gameState.shortCode,
      playersInGame: players?.length
    })
    setGamePhase({variables: {id: gameState.id, phase: 'round'}} )
  }

  const endRound = () => {
    // set game phase to "scoring"
    // the server will take care of updating scores and incrementing currentRound
    if (unreadyPlayers.length > 0) {
      // Prompt the user before leaving the screen
      const confirmed = confirm('Not all players have finished filling out their wine keys. Are you sure you\'re ready to end the round?')
      if (confirmed === true) {
        setGamePhase({variables: {id: gameState.id, phase: 'scoring'}} )
        trackWithProperties('Ended Round', {
          isPlayer: false,
          gameId: gameState.id,
          gameCode: gameState.shortCode,
          playersInGame: players?.length
        })
      } 
    } else {
      setGamePhase({variables: {id: gameState.id, phase: 'scoring'}} )
      trackWithProperties('Ended Round', {
        isPlayer: false,
        gameId: gameState.id,
        gameCode: gameState.shortCode,
        playersInGame: players?.length
      })
    }
  }

  const sortedRounds = [...gameState.rounds].sort((a,b) => {return a.indexInGame - b.indexInGame})
  return (
    <WineListContainer>
        <ScrollView horizontal={true} contentContainerStyle={{ alignItems: 'center'}}>
          {sortedRounds.map( (r, i) => {
            const isActiveRound = activeRound?.id === r.id 
            return (
            <RoundContainer key={r.id}>
              {screenShareEnabled ?
              <Round round={r}></Round>
              :
              <Wine wine={r.wine} onPress={ () => navigation.push('AddWine', {gameState, round: r}) } disabled={r.completed || (isActiveRound && gameState.phase === 'round')}/>
              }
              {isActiveRound && <PillButton 
                label={gameState.phase !== 'round' ? 'Start Round' : 'End Round'} 
                onPress={gameState.phase !== 'round' ? startRound : endRound }
                disabled={players.length === 0 || setGamePhaseLoading}
              />}
            </RoundContainer>
            )
          })}
        </ScrollView>
      </WineListContainer>
  )
}

export default WineList
