import { equals } from "ramda"
import { Platform } from "react-native"
import { MIXPANEL_TOKEN, AND_ANOTHER } from "@env"

const Mixpanel = require("mixpanel-browser")

let mixpanel
let lastProps
let lastEvent

const useMixpanel = () => {
  if (Platform.OS === "web") {
    Mixpanel.init(MIXPANEL_TOKEN)
  } else {
    mixpanel = (callback) =>
      Mixpanel.sharedInstanceWithToken(MIXPANEL_TOKEN)
        .then(() => callback())
        .catch((error) =>
          console.error("Failed to initialize Mixpanel: ", error)
        )
  }

  // Track event without properties
  const track = async (event) => {
    if (mixpanel) {
      mixpanel(() => Mixpanel.track(event))
    } else {
      Mixpanel.track(event)
    }

    lastEvent = event
  }

  // Track event with properties
  const trackWithProperties = async (event, properties) => {
    // If properties of target object are same as previous, don't capture duplicate events
    if (lastProps && lastProps["token"]) {
      delete lastProps["token"] // I have no idea why this property is being added
    }
    if (equals(lastProps, properties)) return

    lastProps = properties

    if (mixpanel) {
      mixpanel(() => Mixpanel.trackWithProperties(event, properties))
    } else {
      Mixpanel.track(event, properties)
    }

    lastEvent = event
  }

  // Identify, i.e. associate to an existing mixpanel profile
  const identify = async (sommifyUserId) => {
    if (mixpanel) {
      mixpanel(() => Mixpanel.identify(sommifyUserId))
    } else {
      Mixpanel.identify(sommifyUserId)
    }
  }

  // Set the value of a user's persisted property (e.g. email, etc.)
  const set = async (propertyAndValue) => {
    if (mixpanel) {
      mixpanel(() => Mixpanel.set(propertyAndValue))
    } else {
      Mixpanel.people.set(propertyAndValue)
    }
  }

  // Increment a property on a specific user
  const increment = async (property) => {
    if (mixpanel) {
      mixpanel(() => Mixpanel.increment(property, 1))
    } else {
      Mixpanel.people.increment(property, 1)
    }
  }

  // Append items to a list property
  const union = async (list, items) => {
    if (mixpanel) {
      mixpanel(() => Mixpanel.union(list, items))
    } else {
      Mixpanel.people.union(list, items)
    }
  }

  // Reset the identity (for logout etc)
  const reset = async () => {
    if (mixpanel) {
      mixpanel(() => Mixpanel.reset())
    } else {
      Mixpanel.reset()
    }
  }

  /**
   * Loop through an objects properties, checking each for null,
   * and if it is null, then delete the key.
   */
  const clean = (obj) => {
    Object.keys(obj).forEach((key) => obj[key] === null && delete obj[key])
    return obj
  }

  return {
    track,
    trackWithProperties,
    identify,
    set,
    increment,
    union,
    reset,
  }
}

export default useMixpanel
