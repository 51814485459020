import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import HomeScreen from './src/screens/homeScreen/HomeScreen'
import RegisterPlayer from './src/screens/registerPlayer/RegisterPlayer'
import NewGame from './src/screens/newGame/NewGame'
import Game from './src/screens/game/Game'
import SommView from './src/screens/game/components/sommView/SommView'
import AddWine from './src/screens/game/components/sommView/components/AddWine'
import { initApollo } from './src/utils/apolloHelper'
import { ApolloProvider } from '@apollo/client'
import { getGameState } from './src/utils/storageManager'

const client = initApollo();
const Stack = createStackNavigator()

const sommifyHeaderStyle = {
  headerStyle: {
    backgroundColor: 'black',
    shadowColor: 'transparent',
    shadowRadius: 0,
        shadowOffset: {
            height: 0,
        },
    borderBottomWidth: 0,
    elevation: 0
  },
  headerTintColor: 'white',
  title: ''
}

export function AppNavigator() {

  return (
    <NavigationContainer>
        <Stack.Navigator initialRouteName="Home">
          <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
          <Stack.Screen name="NewGame" component={NewGame} options={sommifyHeaderStyle}/>
          <Stack.Screen name="RegisterPlayer" component={RegisterPlayer} options={sommifyHeaderStyle} />
          <Stack.Screen name="Game" component={Game} options={sommifyHeaderStyle}/>
          <Stack.Screen name="SommView" component={SommView} options={sommifyHeaderStyle} />
          <Stack.Screen name="AddWine" component={AddWine} options={sommifyHeaderStyle} />
        </Stack.Navigator>
      </NavigationContainer> 
  )
}

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previousGameState: null
    }
  }

  async componentWillMount() {
    const previousGameState = await getGameState()
    if (previousGameState) {
      this.setState({previousGameState})
    }
  }

  render() {

    return (
      <ApolloProvider client={client}>
        <AppNavigator initialState={this.state.previousGameState} />
      </ApolloProvider>
    )
  }
}

