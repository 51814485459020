import React from 'react';
import { View, Text, Image } from 'react-native'
import styled from 'styled-components/native'

const PlayerContainer = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`

const Player = styled(Text)`
  color: white;
  width: 60%;
  font-size: 22px;
`

const ThisPlayer = styled(Text)`
  color: white;
  font-weight: 700
  font-size: 22px;
  width: 60%;
`

const StatusIcon = styled(Image)`
  width: 40px;
  height: 40px;
  margin-right: 15px;
  transform: rotate(22.5deg)
`

const Score = styled(Text)`
  font-size: 20px;
  color: white;
`

const PlayerLine = ({remotePlayer, userPlayer, wineType = 'red'}) => {

  const isThisPlayer = userPlayer && remotePlayer.id === userPlayer.id

  return (
    <PlayerContainer >
      <StatusIcon
        source={remotePlayer.ready ? require(`../../../../../assets/wineglass-${wineType}-nocircle.png`) : require(`../../../../../assets/wineglass-empty-nocircle.png`) }
      />
      {isThisPlayer ? <ThisPlayer key={remotePlayer.id}>{remotePlayer.displayName}</ThisPlayer> : <Player>{remotePlayer.displayName}</Player>}
      <Score>{remotePlayer.score}</Score>
    </PlayerContainer>
  )

}

export default PlayerLine