import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'

const PillButton = ({disabled, label, onPress}) => {

  return (
    <TouchableOpacity
      style={disabled ? styles.disabledInputButton : styles.inputButton}
      disabled={disabled}
      onPress={onPress}
    >
      <Text style={{color: 'white'}}>{label}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  disabledInputButton: {
    backgroundColor: 'gray', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingVertical: '7.5px', 
    paddingHorizontal: '10px', 
    borderRadius: '10px', 
    left: '-5px'
  },
  inputButton: {
    backgroundColor: 'rgb(136, 39, 52)', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingVertical: '7.5px', 
    paddingHorizontal: '10px', 
    borderRadius: '10px', 
    left: '-5px'
  },
})

export default PillButton
