import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native'
import keyMap from '../../../keyMap'

const WineContainer = styled(View)`
  position: relative;
  background-color: rgb(200,200,200)
  padding: 10px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
`

const Grape = styled(Text)`
  color: black;
  font-size: 12px;
  font-weight: 700;
`

const Country = styled(Text)`
  color: black;
  font-size: 12px;
`

const TextContainer = styled(View)`
  position: absolute; 
  bottom: 90;
  left: 0;
`

const Wine = ({ wine, onPress, disabled }) => {

  const grape = keyMap[wine.grape] ? keyMap[wine.grape] : wine.grape
  const country = keyMap[wine.country] ? keyMap[wine.country] : wine.country

  return (
      <WineContainer>
        <TouchableOpacity onPress={onPress} disabled={disabled} style={{position: 'relative'}}>
          <Image
            source={require(`../../../../../../assets/wineglass-${wine.type}.png`)}
            style={{width: 175, height: 175, left: -95, top: -35, zIndex: -1}}
          /> 
          <TextContainer>
            <Grape>{grape}</Grape>
            <Country>{country}</Country>
          </TextContainer>
        </TouchableOpacity>
      </WineContainer>
  )
}

export default Wine