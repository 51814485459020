import React from 'react'
import { View, Text } from 'react-native'
import ConfettiCannon from 'react-native-confetti-cannon'
import Wine from '../sommView/components/Wine'
import keyMap from '../../keyMap'
import styled from 'styled-components/native'
import { path } from 'ramda'
import PillButton from '../../../../components/PillButton'
import AnimatedContainer from '../../../../components/AnimatedContainer'

const ResultsContainer = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: black;
  padding: 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const CallContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`

const ResultsHeader = styled(Text)`
  font-size: 24px;
  font-weight: 300;
  color: white;
  margin-bottom: 40px;
`

const Call = styled(Text)`
  font-size: 18px;
  color: white;
  margin-top: 18px;
  align
`

const Explainer = styled(Text)`
  font-size: 18px;
  color: white;
  margin-top: 40px;
  margin-bottom: 22px;
`

const Result = styled(Text)`
  color: white;
  font-size: 18px;
`

const ButtonContainer = styled(View)`
  margin-top: 80px;
`

const BonusContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`

const RoundResults = ({ gameState, setRoundResultsComplete, player }) => {
  
  const incompleteRounds = gameState.rounds.sort((a,b) => a.indexInGame - b.indexInGame).filter(r => !r.completed)
  const currentRound = incompleteRounds[0]

  // Because of how the game logic works, the scored round is not the current round, but the round that just ended (current round -1)
  // Or, if the rounds have all been completed, it's simply the final round in the game.
  const scoredRound = currentRound ? gameState.rounds[currentRound.indexInGame - 1] : gameState.rounds[gameState.rounds.length - 1]
  const wine = path(['wine'], scoredRound)
  const playerKeys = player.wineKeys
  
  // probably want to return a loading indicator here
  if (!playerKeys || !scoredRound) return null
  const wineKey = playerKeys.filter(k => k.roundId === scoredRound.id)[0]
  if (!wineKey) return null
  const allCorrect = wine.world === wineKey.world && wine.country === wineKey.country && wine.grape === wineKey.grape 
  
  let currentDelay = 0
  let delayIncrement = 1250

  return (
    <ResultsContainer>
      <AnimatedContainer delay={currentDelay++}>
        <ResultsHeader>{`Your Round ${scoredRound.indexInGame + 1} Results`}</ResultsHeader>
      </AnimatedContainer>
      { (wine && wineKey) && 
        <>
          <AnimatedContainer delay={currentDelay += delayIncrement}>
            <Wine wine={wine} />
          </AnimatedContainer>
          <AnimatedContainer delay={currentDelay += delayIncrement}>
            <Explainer>This round's wine was ...</Explainer>
          </AnimatedContainer>
          
          <AnimatedContainer delay={currentDelay += delayIncrement} style={{width: '80%'}}>
            <CallContainer>
              <Call>... from the {`${keyMap[wine.world]}`}</Call><Result>{wine.world === wineKey.world ? '+25  😊' : '😬'} </Result>
            </CallContainer>
          </AnimatedContainer>
          
          <AnimatedContainer delay={currentDelay += delayIncrement} style={{width: '80%'}}>
            <CallContainer>
              <Call>... from {`${keyMap[wine.country]}`}</Call><Result>{wine.country === wineKey.country ? '+25  😊' : '😬'} </Result>
            </CallContainer>
          </AnimatedContainer>
          <AnimatedContainer delay={currentDelay += delayIncrement} style={{width: '80%'}}>
            <CallContainer>
              <Call>... a {`${keyMap[wine.grape]}`}</Call><Result>{wine.grape === wineKey.grape ? '+25  😊' : '😬'} </Result>
          </CallContainer>
          </AnimatedContainer>
          {allCorrect && 
          <>
            <AnimatedContainer delay={currentDelay += delayIncrement}>
              <BonusContainer>
                <Result>Perfect Round! +25 🎉</Result>
              </BonusContainer>
              
            </AnimatedContainer>

            <AnimatedContainer delay={currentDelay} style={{position: 'absolute', left: -10, top: -10, height: '100%', width: '100%'}}>
              <ConfettiCannon 
                count={100} 
                origin={{x: -10, y: -10}} 
                autoStartDelay={currentDelay}
                fadeOut={true}
                explosionSpeed={0}
              />
            </AnimatedContainer>
          </>
          }
          <ButtonContainer>
            <PillButton label={'Continue'} onPress={() => setRoundResultsComplete(true)}/>
          </ButtonContainer>
        </>
      }
    </ResultsContainer>
  )
}

export default RoundResults