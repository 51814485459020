import gql from 'graphql-tag'

export const CREATE_GAME = gql`
  mutation createGame {
    createGame {
      id
      shortCode
      phase
      players {
        id
        displayName
        score
        ready
      }
      rounds {
        id
      }
      currentRound
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser($email: String!) {
    createUser(email: $email) {
      id
    }
  }
`

export const CREATE_PLAYER = gql`
  mutation createPlayer($displayName: String!, $userId: String!, $gameId: String!) {
    createPlayer(displayName: $displayName, userId: $userId, gameId: $gameId) {
      id
      userId
      gameId
      ready
      displayName
    }
  }
`

export const DEACTIVATE_PLAYER = gql`
  mutation deactivatePlayer($id: String!) {
    deactivatePlayer(id: $id) 
  }
`

export const CREATE_ROUND = gql`
  mutation createRound($wineId: String!, $gameId: String!, $roundId: String) {
    createRound(wineId: $wineId, gameId: $gameId, roundId: $roundId) {
      id
    }
  }
`

export const SET_GAME_PHASE = gql`
  mutation setGamePhase($id: String!, $phase: String!){
    setGamePhase(id: $id, phase: $phase)
  }
`

export const COMPLETE_ROUND = gql`
  mutation completeRound($roundId: String!){
    completeRound(roundId: $roundId)
}
`

export const SET_GAME_NAME = gql`
  mutation setGameName($id: String!, $name: String!){
    setGameName(id: $id, name: $name)
  }
`

export const SET_TIMER_LENGTH = gql`
  mutation setTimerLength($id: String!, $timerLength: Int!){
    setTimerLength(id: $id, timerLength: $timerLength)
  }
`

export const OPEN_GAME = gql`
  mutation openGame($id: String!){
    openGame(id: $id)
  }
`

export const UPDATE_WINEKEY = gql`
  mutation createWineKey(
    $playerId: String!, 
    $roundId: String!, 
    $world: World,
    $country: Country,
    $grape: Grape
    ) {
    createWineKey(
      playerId: $playerId, 
      roundId: $roundId, 
      world: $world,
      country: $country,
      grape: $grape 
    ) {
      id
    }
  }
`

