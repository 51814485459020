import React, { useEffect, useState } from 'react'
import { Image, Text, TextInput, View, TouchableOpacity, StyleSheet, Platform, KeyboardAvoidingView } from 'react-native'
import PillButton from '../../components/PillButton'
import { useLazyQuery, useMutation } from '@apollo/client'
import { gameQuery } from '../../networking/queries'
import { CREATE_GAME, DEACTIVATE_PLAYER } from '../../networking/mutations'
import styled from 'styled-components/native'
import { getGameState, getPlayerState, clearGameState, clearPlayerState  } from '../../utils/storageManager'
import useMixpanel from '../../utils/useMixpanel'
import Constants from 'expo-constants'

const NoGameWarning = styled(Text)`
  font-size: 12;
  color: white;
`

const WarningContainer = styled(View)`
  height: 20px;
  margin: 0;
  padding: 0;
`

const SommifyHeader = styled(Text)`
  font-size: 42px;
  font-weight: 300;
  color: white; 
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: 'Avenir'
`

export default function HomeScreen({ navigation }) {

  const [value, onChangeText] = useState('')
  const [displayWarning, setDisplayWarning] = useState(false)
  const disabled = value.length !== 4
  const version = Constants.manifest.version 
  const environment = Constants.manifest.extra.environment

  const [gameState, setGameState] = useState(null)
  const [deactivatePlayer] = useMutation(DEACTIVATE_PLAYER)
  const { track, trackWithProperties } = useMixpanel()

  

  if (!gameState) {
    getGameState().then((value) => {

      if ( value && value.phase === 'end' ) {
        alert('This game has now ended!')
        trackWithProperties('Loaded Game After End', {
          gameId: value.id,
          gameCode: value.shortCode,
          clientVersion: version,
          clientEnvironment: environment
        })
        clearGameState()
        getPlayerState().then((value) => {
          if (value) {
            deactivatePlayer({variables: {id: value.id}})
          }
        })
        clearPlayerState()
      } else if (value) { 
        trackWithProperties('Loaded Game', {
          gameId: value.id,
          gameCode: value.shortCode,
          clientVersion: version,
          clientEnvironment: environment
        })
        setGameState(value)
      } else {
        // only track if the user isn't being forwarded to a game
        track('Viewed Home Page')
      }
    })
  }

  const [createNewGame] = useMutation(CREATE_GAME, {
    onCompleted: (gameData) => {
      trackWithProperties('Created New Game', {
        isPlayer: false,
        gameCode: gameData.createGame.shortCode,
        gameId: gameData.createGame.id,
        clientVersion: version,
        clientEnvironment: environment
      })
      navigation.push('Game', { fetchedGame: gameData.createGame })
    },
    onError: error => {
      trackWithProperties('Triggered Error', {
        attemptType: 'mutation',
        attemptValue: 'CREATE_GAME',
        errorMessage: error.message,
        clientVersion: version,
        clientEnvironment: environment
      })
      console.log(error)
    },
  })

  const [getGame] = useLazyQuery(gameQuery, {
    onCompleted: (data) => {
      if (data && data.game) {
        setDisplayWarning(false)
        trackWithProperties('Entered Game Code', {
          gameCode: data.game.shortCode,
          clientVersion: version,
          clientEnvironment: environment
        })
        navigation.push('RegisterPlayer', { fetchedGame: data.game })
      } else if ( data && !data.game ) {
        trackWithProperties('Entered Invalid Game Code', {
          gameCode: value,
          clientVersion: version,
          clientEnvironment: environment
        })
        setDisplayWarning(true)
      }
    },
    onError: error => {
      trackWithProperties('Triggered Error', {
        attemptType: 'query',
        attemptValue: 'gameQuery',
        errorMessage: error.message,
        clientVersion: version,
        clientEnvironment: environment
      })
      console.log(error)
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (gameState) {
      const stackHeight = navigation.dangerouslyGetState().routes.length
      // prevent multiple instances of the game screen from being pushed onto the stack
      if (stackHeight === 1) {
        navigation.push('Game')
      }
    } 
  }, [gameState])

  return (
    <KeyboardAvoidingView 
      behavior={Platform.OS === "ios" ? "padding" : "height"}  
      style={styles.container}
    >
      <SommifyHeader>Beat The Somm</SommifyHeader> 
      <Image 
        style={styles.sommifyLogo}
        source={require('../../../assets/wineglass.png')}
      />
      <Text style={styles.sommifyTagline}>A Virtual Blind Tasting Game</Text> 
      <WarningContainer>
        {displayWarning && <NoGameWarning>Oops! That doesn't seem to be a valid game.</NoGameWarning>}
      </WarningContainer>
      <View style={styles.inputWithButton}>
        <TextInput 
          style={styles.input}
          enablesReturnKeyAutomatically
          onChangeText={text => {
            const allowedCharacters = /^[A-Za-z]+$/
            if ( text.match(allowedCharacters) && text.length <= 4 || text === '') {
              setDisplayWarning(false)
              onChangeText(text.toUpperCase())
            }
          }}
          value={value}
          placeholder={'Enter a game code here'}
        />
        <PillButton 
          disabled={disabled}
          onPress={() => getGame({ variables: {
            shortCode: value
          }})}
          label="JOIN"
        />
      </View>
      <TouchableOpacity onPress={createNewGame} style={styles.touchableContainer}>
       <Text style={styles.textButton}>Want to host? Start a new game instead!</Text>
      </TouchableOpacity>
      <Text style={styles.version}>v. {version}</Text> 
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: { 
    flex: 1, 
    alignItems: 'center', 
    backgroundColor: 'black',
    padding: 20
  },
  sommifyLogo: {
    marginBottom: 20, 
    width: 250, 
    height: 250
  },
  sommifyTagline: {
    fontSize: '26px', 
    fontWeight: '300', 
    color: 'white', 
    marginBottom: 20,
    fontFamily: 'Avenir'
  },
  version: {
    fontSize: '10px',
    color: 'rgba(255,255,255,0.5)', 
    marginBottom: 10,
    alignSelf: 'flex-end',
    marginTop: 'auto',
    fontFamily: 'Avenir'
  },
  inputBlock: {
    justifyContent: 'flex-start', 
    width: 300
  },
  inputLabel: {
    color: 'white', 
    fontSize: 18
  },
  inputWithButton: {
    flexDirection: 'row', 
    alignContent: 'space-between', 
    alignItems: 'center', 
    width: 300, 
    backgroundColor: 'white', 
    borderRadius: 10,
    marginTop: 10, 
    height: 40
  },
  input: { 
    width: 300, 
    height: 40, 
    margin: '20px', 
    color: 'rgb(136, 39, 52)', 
    fontWeight: '300', 
    outlineWidth: '0',
    outline: 'none'
  },
  touchableContainer: {
    padding: '15px'
  },
  textButton: {
    color: 'rgb(198,53,71)',
  }
})