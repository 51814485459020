import React, { useState, useEffect, useRef } from 'react'
import { View, Text, TextInput } from 'react-native'
import { Switch } from 'react-native-switch'
import { SET_GAME_PHASE, SET_GAME_NAME, SET_TIMER_LENGTH } from '../../../../networking/mutations'
import useMixpanel from '../../../../utils/useMixpanel'
import Constants from 'expo-constants'
import { useMutation } from '@apollo/client'
import styled from 'styled-components/native'
import PillButton from '../../../../components/PillButton'
import WineList from './components/WineList'
import PlayerLine from '../statusBoard/PlayerLine'
import TimerControl from './components/TimerControl'

const StatusBoardContainer = styled(View)`
  display: flex;
  flex-direction: row;
  background-color: black;
  min-height: 100%;
`

const LeftPanel = styled(View)`
  flex-direction: column;
  padding: 40px;
  width: 60%;
`

const RightPanel = styled(View)`
  flex-direction: column;
  padding: 40px;
  width: 40%;
  border: 2px dotted;
  border-left-color: white;
`

const SlidesContainer = styled(View)`
`

const SlidesFrame = styled(View)`
  width: 600px;
  height: 400px;
  background-color: black;
`

const HeaderWithButton = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-bottom: 20px;
  padding-right: 20px;
`

const SectionHeader = styled(Text)`
  font-size: 24px;
  font-weight: 300;
  color: white;
`

const RoundButtons = styled(View)`
  display: flex;
  flex-direction: row;
`

const Explainer = styled(Text)`
  font-size: 16px;
  color: white;
  margin-top: 10px;
`

const SettingsContainer = styled(View)`
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const SettingsOptionContainer = styled(View)`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: flex-end;
`

const SettingsSwitchContainer = styled(View)`
  display: flex;
  flex-direction: column;
`

const SettingsInputContainer = styled(View)`
  display: flex;
  flex-direction: row;
`

const PlayerList = styled(View)`
  flex-direction: column;
  margin-top: 20px;
`

const SommView = ({ gameState, navigation }) => {

  const [setGamePhase, { loading: setGamePhaseLoading }] = useMutation(SET_GAME_PHASE)
  const [setGameName] = useMutation(SET_GAME_NAME)
  const [setNewTimerLength] = useMutation(SET_TIMER_LENGTH)
  const player = null
  const players = gameState.players ? gameState.players.sort((a,b) => {return b.score - a.score}) : []
  const rounds = [...gameState.rounds]
  const sortedRounds = rounds.sort((a,b) => {return a.indexInGame - b.indexInGame})
  const incompleteRounds = sortedRounds.filter(r => !r.completed)
  const allRoundsComplete = incompleteRounds.length === 0 && gameState.phase !== 'welcome'
  const unreadyPlayers = players.filter(p => !p.ready)
  const activeRound = incompleteRounds[0]
  const wineType = activeRound ? activeRound.wine.type : gameState.rounds[gameState.rounds.length - 1]?.wine?.type

  const [screenShareEnabled, setScreenShareEnabled] = useState(false)
  const [roomNamable, setRoomNamable] = useState(false)
  const [roomName, setRoomName] = useState('')
  const [timerActive, setTimerActive] = useState(false)
  const [timerLength, setTimerLength] = useState(gameState.timerLength)

  const [lastCalled, setLastCalled] = useState(null)
  const timer = useRef(0);

  const version = Constants.manifest.version 
  const environment = Constants.manifest.extra.environment

  const { trackWithProperties } = useMixpanel()

  const explainerText = gameState.phase === 'end' ? 'The game is over!' : (allRoundsComplete ? 'All rounds are complete! You can either end the game, or add more rounds to keep going!' : 'Add some wines, then start the first round when everybody is ready!')

  const broadcastNewTimerLength = (length) => {
    if (length < 60000 || length > 600000) return
    setTimerLength(length)
    setNewTimerLength({variables: { id: gameState.id, timerLength: length }} )
  }

  const endGame = () => {
    // set game phase to "scoring"
    // the server will take care of updating scores and incrementing currentRound
    setGamePhase({variables: {id: gameState.id, phase: 'end'}} )

    trackWithProperties('Ended Game', {
      isPlayer: false,
      gameId: gameState.id,
      gameCode: gameState.shortCode,
      playersInGame: players?.length,
      clientVersion: version,
      clientEnvironment: environment
    })
  }

  useEffect(() => {
      if (!lastCalled || (Date.now() - lastCalled > 1000)) {
        setGameName( {variables: {id: gameState.id, name: roomName }} )
        setLastCalled(Date.now())
        clearTimeout(timer.current);
      } else {
        // The event was fired too recently, but we still want
        // to fire this event after the timeout if it is the last one
        timer.current = setTimeout(() => {
          setLastCalled(Date.now())
            setGameName( {variables: {id: gameState.id, name: roomName }} )
          }, 1000);
        }
      // Cleanup the timer if there was one and this effect is reloaded.
     return () => clearTimeout(timer.current);
  }, [roomName])

  useEffect(() => {
    if (!roomNamable) {
      setRoomName('')
      setGameName( {variables: {id: gameState.id, name: '' }} )
    }
  }, [roomNamable])

  useEffect(() => {
    if (gameState.timerLength && gameState.timerLength !== timerLength) {
      setTimerLength(gameState.timerLength)
    }
  }, [gameState.timerLength])

  return (
    <StatusBoardContainer>
      <LeftPanel>
      <HeaderWithButton>
        <SectionHeader>{`You are hosting game ${gameState.shortCode} ${(roomName !== '' && roomNamable) ? '(' + roomName + ')' : ''}`}</SectionHeader>
        <PillButton label={screenShareEnabled ? 'Leave Screen Share Mode' : 'Enter Screen Share Mode'} onPress={() => setScreenShareEnabled(!screenShareEnabled)} />
      </HeaderWithButton>
      <Explainer>{`${explainerText}`}</Explainer>
      <SettingsContainer>
        <SettingsOptionContainer>
          <SettingsSwitchContainer>
            <Text style={{color: 'white', marginBottom: '5px'}}>Custom Room Name</Text>
            <Switch 
              onValueChange={() => setRoomNamable(!roomNamable)}
              value={roomNamable}
              backgroundActive={'rgb(136, 39, 52)'}
              backgroundInactive={'rgb(136, 139, 152)'}
              circleActiveColor={'rgb(198,53,71)'}
              circleInActiveColor={'rgb(198,203,223)'}
              renderActiveText={false}
              renderInActiveText={false}
              circleBorderWidth={0}
              circleSize={20}
            />
          </SettingsSwitchContainer>
          <SettingsInputContainer>
            {roomNamable && 
            <TextInput 
              style={{borderBottomColor: 'white', color: 'white', outlineWidth: '0', borderBottomWidth: '1px', borderBottomColor: 'white'}}
              onChangeText={text => {
                setRoomName(text)
              }}
              value={roomName}
            />}
          </SettingsInputContainer>
        </SettingsOptionContainer>
        <SettingsOptionContainer>
          <SettingsSwitchContainer>
            <Text style={{color: 'white', marginBottom: '5px'}}>Enable Round Timer</Text>
            <Switch 
              onValueChange={() => setTimerActive(!timerActive)}
              value={timerActive}
              backgroundActive={'rgb(136, 39, 52)'}
              backgroundInactive={'rgb(136, 139, 152)'}
              circleActiveColor={'rgb(198,53,71)'}
              circleInActiveColor={'rgb(198,203,223)'}
              renderActiveText={false}
              renderInActiveText={false}
              circleBorderWidth={0}
              circleSize={20}
            />
          </SettingsSwitchContainer>
          <SettingsInputContainer>
            {!!timerActive && <TimerControl timerLength={timerLength} broadcastNewTimerLength={broadcastNewTimerLength} />}
          </SettingsInputContainer>
        </SettingsOptionContainer>
      </SettingsContainer>
      <HeaderWithButton>
        <SectionHeader>Wines / Rounds</SectionHeader>
        {gameState.phase !== 'end' && <RoundButtons>
          {allRoundsComplete && <View style={{marginRight: '10px'}}><PillButton label='End the Game' onPress={() => endGame()} /></View>}
          <PillButton label='Add a Round' onPress={() => navigation.push('AddWine', {gameState}) }/>
        </RoundButtons>}
      </HeaderWithButton>
      <WineList 
        gameState={gameState}
        navigation={navigation}
        activeRound={activeRound}
        screenShareEnabled={screenShareEnabled}
        players={players}    
        unreadyPlayers={unreadyPlayers}
        setGamePhase={setGamePhase}
        setGamePhaseLoading={setGamePhaseLoading}
      />
      <SlidesContainer>
        <SlidesFrame>

        </SlidesFrame>
      </SlidesContainer>
      </LeftPanel>
      <RightPanel>
        <SectionHeader>Players</SectionHeader>
        <PlayerList>
        {players.map( p => <PlayerLine remotePlayer={p} userPlayer={player} wineType={wineType} key={p.id}/>)}
        </PlayerList>
      </RightPanel>
    </StatusBoardContainer>
  )
}

export default SommView