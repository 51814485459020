import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'

const WineKeyButton = ({disabled, label, onPress, wineType}) => {
  const buttonStyle = wineType === 'red' ? styles.inputButtonRed : styles.inputButtonWhite
  return (
    <TouchableOpacity
      style={disabled ? styles.disabledInputButton : buttonStyle}
      disabled={disabled}
      onPress={onPress}
    >
      <Text style={{color: 'white'}}>{label}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  disabledInputButton: {
    backgroundColor: 'gray', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingVertical: '10px', 
    paddingHorizontal: '20px', 
    borderRadius: '10px', 
  },
  inputButtonRed: {
    backgroundColor: 'rgb(136, 39, 52)', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingVertical: '10px', 
    paddingHorizontal: '20px', 
    borderRadius: '10px', 
  },
  inputButtonWhite: {
    backgroundColor: 'rgb(255, 204, 102)', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingVertical: '10px', 
    paddingHorizontal: '20px', 
    borderRadius: '10px', 
  },
})

export default WineKeyButton
